.header {
    background-color: black;
    position: fixed;
    width: 100%;
    z-index: 10;

}

.background-primary{
    background-color: #e5640c  !important;
}

.background-primary:hover{
    background-color: #ec9254 !important;
}


@media (max-width: 800px) {
    main {
        padding: 2rem 0rem;
    }
  }
