.tablewrapper {
    margin:  1rem 0;
    height: 100%;
    width: 95vw;
    overflow: auto;
}

.darker{
    background-color: #282c34 !important;
    position: sticky !important;
    left: 0 !important;
    z-index: 1 !important;
    font-weight: bold;
}