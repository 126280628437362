#bottomParticles {
  height: 100%;
  position: absolute;
  width: 100%;
  overflow: hidden;
}

.grid-flow {
  display: grid;
  grid-template-columns: 20% 50% 30%;
  grid-template-rows: repeat(1, 1fr);
  width: 100%;
  align-items: center;
}

.grid-flow-first {
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
}

.grid-flow-second {
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
}

.grid-flow-third {
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
}

.grid-flow-first::after {
  content: '';
  position: absolute;
  width: clamp(0.0625rem, 0.0256rem + 0.1553vw, 0.125rem);
  height: 100%;
  background-color: #fff;
  z-index: 2;
  right: 0;
  top: 0;
}
.grid-flow-second::after {
  content: '';
  position: absolute;
  width: clamp(0.0625rem, 0.0256rem + 0.1553vw, 0.125rem);
  height: 100%;
  background-color: #fff;
  z-index: 2;
  right: 0;
  top: 0;
}


.grid-flow-first::before {
  content: '';
  position: absolute;
  width: 100%;
  height: clamp(0.0625rem, 0.0256rem + 0.1553vw, 0.125rem);
  background-color: #fff;
  z-index: 2;
  left: 0;
  top: 100%;
}
.grid-flow-second::before {
  content: '';
  position: absolute;
  width: 100%;
  height: clamp(0.0625rem, 0.0256rem + 0.1553vw, 0.125rem);
  background-color: #fff;
  z-index: 2;
  left: 0;
  top: 100%;

}
.grid-flow-third::before {
  content: '';
  position: absolute;
  width: 100%;
  height: clamp(0.0625rem, 0.0256rem + 0.1553vw, 0.125rem);
  background-color: #fff;
  z-index: 2;
  left: 0;
  top: 100%;

}

.grid-flow img {
  object-fit: contain;
  padding: clamp(1rem, -0.9767rem + 4.6512vw, 2rem);
  max-width: 100%;
  max-height: 85%;

}



.grid-flow h2 {
  color: white;
  font-size: var(--font-medium);
  position: relative;
}

.grid-flow h2::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
  z-index: 2;
  bottom: 0;
  left: 0;
}

.grid-flow-icons .icon {
  -webkit-filter: invert(100%); /* Safari/Chrome */
  filter: invert(100%);
  width: 25%;
  object-fit: contain;
}

.grid-flow-icons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  align-items: end;
  gap: 1rem;
  padding: 2rem 0;
}

.icon-topic {
  display: grid;
  grid-template-rows: 1fr 1fr;
  justify-items: center;
  align-items: center;
  height: 100%;
}

.grid-flow-icons h4 {
  color: white;
  font-size: var(--font-sm-md);
}

.sponsors p {
  color: white;
  padding: 2rem;
  font-size: var(--font-small);
}

.sponsors {
  padding: 2rem;
  text-align: center;
  position: relative;
}

@media (max-width: 680px) {
  .grid-flow {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    width: 100%;
    align-items: center;
  }

  .grid-flow-first {
    position: relative;
    height: 100%;
    width: 100%;
    grid-row: 1;
    grid-column: span 8;
    align-items: center;
  }
  
  .grid-flow-second {
    position: relative;
    height: 100%;
    width: 95vw;
    grid-row: 2;
    display: grid;
    align-items: center;
  }

  .grid-flow-third{
    position: relative;
    height: 100%;
    width: 95vw;
    grid-row: 3;
    align-items: center;

  }
  .grid-flow-third::after {
    content: '';
    position: absolute;
    width: 45%;
    height: clamp(0.0625rem, 0.0256rem + 0.1553vw, 0.125rem);
    background-color: #fff;
    z-index: 2;
    top: 100%;
    left: 27.5%;
  }
  
  .grid-flow-third::before {
    content: '';
    position: absolute;
    width: 45%;
    height: clamp(0.0625rem, 0.0256rem + 0.1553vw, 0.125rem);
    background-color: #fff;
    z-index: 2;
    top: 90%;
    left: 27.5%;
  }
  
.grid-flow-second::after {
  content: '';
  position: absolute;
  width: 45%;
  height: clamp(0.0625rem, 0.0256rem + 0.1553vw, 0.125rem);
  background-color: #fff;
  z-index: 2;
  top: 100%;
  left: 27.5%;
}

.grid-flow-second::before {
  content: '';
  position: absolute;
  width: 45%;
  height: clamp(0.0625rem, 0.0256rem + 0.1553vw, 0.125rem);
  background-color: #fff;
  z-index: 2;
  top: 90%;
  left: 27.5%;
}

.grid-flow-first::after {
  content: '';
  position: absolute;
  width: 45%;
  height: clamp(0.0625rem, 0.0256rem + 0.1553vw, 0.125rem);
  background-color: #fff;
  z-index: 2;
  top: 100%;
  left: 27.5%;
}

.grid-flow-first::before {
  content: '';
  position: absolute;
  width: 45%;
  height: clamp(0.0625rem, 0.0256rem + 0.1553vw, 0.125rem);
  background-color: #fff;
  z-index: 2;
  top: 90%;
  left: 27.5%;
}

  .grid-flow-icons {
    grid-template-columns: repeat(2, 1fr);
    
  }
  .grid-flow img {
    padding: clamp(0.5rem, -2.1553rem + 11.1801vw, 5rem);
  }
  .grid-flow-shapes img {
    padding: 0%;
  }
  .sponsors {
    padding: 0.5rem;
  }
  .sponsors p {
    padding: 0;
    max-width: 32rem;
    margin: auto;
  }
}
