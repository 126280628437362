/* Page Title */
.mapping {
  padding-bottom: 30vh;
}

/* HRL™ Mapping Services Section */
.grid-base {
  display: grid;
  grid-gap: 2rem;
  justify-items: center;
  align-items: center;
}

.grid-base p {
  font-size: var(--font-small);
}

.margin-large {
  margin: 5rem 8rem;
}

.subheader {
  font-size: var(--font-x-large);
  text-align: left;
}

.column2 {
  grid-template-columns: repeat(2, 1fr);
}

.dashed-line {
  border: 1px dashed #fff;
  width: 90%;
  height: 1px;
  margin: 2rem 0;
}

/* HRL™ Dashboard Section */
.grid-gap-large {
  grid-gap: 8rem;
}

.padding {
  padding: 2rem 0rem;
}

.block {
  border: #fff 3px solid;
  padding: 1rem;
  height: 100%;
  text-align: center;
}

.text-orange {
  color: #f5954f;
}

.grid-gap-small {
  grid-gap: 0.5rem;
}

.hrlDashboard {
  padding-bottom: 1rem;
}

.justify-left {
  justify-self: left;
}

.text-uppercase {
  text-transform: uppercase;
}

/* Strikes Section */
.column3 {
  grid-template-columns: 1fr auto 1fr;
}

.margin-small {
  margin: 5rem 0.5rem;
}

.arrowMap {
  width: 90%;
  height: auto;
}

.style {
  padding: 2rem;
}

.MapImage-overlay {
  position: relative;
}

.MapImage-text {
  position: absolute;
  top: 0.5rem;
  left: 1rem;
  color: white;
  text-align: left;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
  font-size: var(--font-small);
}

.location {
  color: white;
  text-align: right;
}

/*Responsiveness*/
@media (max-width: 1024px) {
  .margin-large {
    margin: 3rem;
  }
  .column2 {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 768px) {
  .margin-large {
    margin: 1rem;
  }

  .column2 {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (max-width: 700px) {
  .column3 {
    grid-template-columns: repeat(1, 1fr);
  }
  .arrowMap {
    transform: rotate(90deg);
    width: 5%;
    height: auto;
  }
}

@media (max-width: 500px) {
  .arrowMap {
    transform: rotate(90deg);
    width: 8%;
    height: auto;
  }
}

/*in progress slider css*/
.imgSlider {
  text-align: center;
  display: flex;
  justify-content: center;
  width: 700px;
  height: 450px;
}

/*in progress slider css*/
* {
  box-sizing: border-box;
}

.img-comp-container {
  position: relative;
  height: 200px; /*should be the same height as the images*/
}

.img-comp-img {
  position: absolute;
  width: auto;
  height: auto;
  overflow: hidden;
}

.img-comp-img img {
  display: block;
  vertical-align: middle;
}

.img-comp-slider {
  position: absolute;
  z-index: 9;
  cursor: ew-resize;
  /*set the appearance of the slider:*/
  width: 40px;
  height: 40px;
  background-color: white;
  opacity: 0.7;
  border-radius: 50%;
}

/* CWPP Section */
.noMarginTopBottom {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.cwppTitle {
  color: white;
  font-size: var(--font-x-large);
  line-height: 0.9;
}

.gridContainer_1 {
  display: grid;
  grid-template-rows: 1fr;
  font-size: var(--font-sm-md);
  text-align: center;
  align-items: center;
  gap: 3vw;
  place-items: center;
}

.bulletPointsGridContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 3fr 12fr 2fr;
  align-items: center;
  text-align: left;
}


.circle{
  background-color: black;
  color: white;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  margin: 0vw 3vw;
}

.slick-slider {
  margin: 1rem;
  height: 100% !important;
}
.firefighterimg {
  width: 60vw;
  padding-top: 2vw;
}
@media (max-width: 1024px) {
  .cwppTitle {
    line-height: 0.9;
    font-size: var(--font-large);
  }
}
@media (max-width: 768px) {
  .cwppTitle {
    line-height: 0.9;
    font-size: var(--font-medium);
  }
  .firefighterimg {
    width: 80vw;
  }
}
