.contactInfo{
    padding-top: 100px; 
}
.iframe-container{
    width: 100%;
    min-height: 675px;
}

.iframe{
    width: 100%;
    height: 100%;
}
@media (max-width: 500px) {
    .iframe-container{
        height: 640px;
    
    }
    .iframe{
        height: 670px;
    
    }
}