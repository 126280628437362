.map-and-dash
{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    min-height: 100vh;
    /* padding-top: 15%; */
}

/* ----------------------------------------- Leaflet Geosearch -----------------------------------------  */
/* Search bar container */

.leaflet-control-geosearch {
    z-index: 500 !important; /* Ensure it is above the map */
    position: absolute !important; /* Position relative to the viewport */
    

    border: 0 transparent !important;
}

.leaflet-geosearch-bar {
    /* width: 20rem !important; */
    padding: 0.5rem !important; /* Inner spacing */
    

    z-index: 500 !important;
    position: absolute !important;
    top: 8%; /* Position from the top of the screen */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%) !important; /* Offset by half of its width */
}

.leaflet-geosearch-bar form
{
    border: 0.15rem solid grey !important;
    border-radius: 1rem !important;
    overflow: hidden !important;
    padding: 0.3rem !important; /* Inner spacing */
}

/* Input field within the search bar */
.leaflet-geosearch-bar input {
    color: #333 !important; /* Dark gray text color */
    font-size: 0.9rem !important; /* Adjust font size */
    border: none !important; /* Remove default border */
    outline: none !important; /* Remove focus outline */
    background: none !important; /* Transparent background */
    flex: 1 !important; /* Let the input field take available space */
    padding: 0.2rem !important; /* Padding for input text */
}

.leaflet-geosearch-bar button
{
    font-size: 1.2rem !important;
    padding-top: 1.4% !important;
}

/* Add focus outline for accessibility */
.leaflet-geosearch-bar input:focus {
    /* outline: 2px solid #0078d7 !important; */
    border-color: transparent;
    --tw-ring-color: transparent; 
}

/* Placeholder text styling */
.leaflet-geosearch-bar input::placeholder {
    color: #aaa !important; /* Light gray placeholder */
    font-style: italic !important; /* Optional: Italicize placeholder text */
}

.leaflet-control-geosearch .results {
    color: #000 !important;
  }

/* ----------------------------------------- Leaflet Geosearch -----------------------------------------  */

/* ----------------------------------------- Leaflet Map -----------------------------------------  */

.leaflet-container 
{
    z-index: 1; /* Ensure the map has a lower z-index than .my-dash */
}

.leaflet-control-zoom {
    bottom: -5rem !important; /* Adjust the value to move it lower */
    top: auto !important; /* Ensure it is no longer pinned to the top */
    border: 0rem transparent !important;
    /* background-color: aquamarine !important; */


    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    gap: 0.7rem !important;
    padding-left: 50% !important;
}

.leaflet-control-zoom-in,
.leaflet-control-zoom-out {
    display: flex !important; /* Enable flexbox for the buttons */
    justify-content: center !important; /* Horizontally center the symbols */
    align-items: center !important; /* Vertically center the symbols */
    border: 0.15rem solid grey !important;
    border-radius: 2rem !important;
    overflow: hidden !important;
    width: 2.5rem !important; /* Set button width */
    height: 2.5rem !important; /* Set button height (ensures a square button) */
    background-color: #e5640c !important; /* Button background color */
    color: aliceblue !important; /* Color of the symbols */
    font-size: 1.2rem !important; /* Adjust size of symbols */
    transition: 0.4s !important; /* Smooth transition */
}

.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover {
    scale: 1.1 !important; /* Add hover scaling effect */
    transition: 0.4s !important;
}


.leaflet-marker-pane img:hover {
    scale: 1.001 !important; /* Add hover scaling effect */
    transition: 0.4s !important;
}


.leaflet-control-zoom-in:hover,
.leaflet-control-zoom-out:hover
{
    /* background-color: #f4f4f4 !important; */
    scale: 1.1 !important;
    transition: 0.4s !important;
}

/* ----------------------------------------- Leaflet Map -----------------------------------------  */
.my-map
{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 1%;
    height: 100vh;
}

.arrow-and-dash
{
    position: absolute;
    margin-top: 5%;
    margin-left: 80%;
    margin-right: 5%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.my-dash
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    /* align-items: center; */
    background-color: #2b2b2b;
    opacity: 0.9;
    
    z-index: 2;
    
    width: 20rem;
    height: 38rem;
    border: 0.15rem  solid grey;
    border-radius: 0.75rem;
    overflow: hidden;
    padding: 0.2rem 1rem;
    
    /* bottom: var(--menu-top-position, 0);
    transition: transform 0.2s ease-in-out;
    transform: translateY(0); */
}


/* .my-dash.hidden {
    transform: translateY(-120%);
} */

.my-dash h1 {
    margin-left: 0; /* Remove the left margin */
    margin-top: 1rem; /* Keep the top margin for spacing */
    padding: 0 1rem; /* Add padding for some spacing inside the h1 */
    color: aliceblue;

    text-align: left; /* Align text to the left */
    border-bottom: 3px solid grey;
    width: 100%; /* Make the h1 span the full width of the parent */
    box-sizing: border-box; /* Ensure padding is included in width calculation */
}

.circle-around-arrow {
    position: absolute;
    bottom: var(--arrow-bottom-position, 5%); /* Adjusts dynamically */

    /* transition-property: bottom, opacity;
    transition-duration: 0.1s; */

    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    background-color: #2b2b2b;
    border: 0.15rem solid grey;
    border-radius: 2rem;
    overflow: hidden;
}

.arrow-icon
{
    color: aliceblue;
    font-size: 2.2rem;
}

.online-offline
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: aliceblue;
    margin-top: 0.8rem;

    width: 100%;

    overflow-y: auto; /* Add vertical scrolling when content exceeds height */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Styling scroll bar from online-offline section */
.online-offline::-webkit-scrollbar {
    width: 5px;
}

.online-offline::-webkit-scrollbar-thumb {
    background-color: grey;
    border-radius: 10px;
}

.online-offline::-webkit-scrollbar-track {
    background-color: #2b2b2b;
}

.online-offline h2
{
    margin-left: 0; /* Remove the left margin */
    margin-top: 1rem; /* Keep the top margin for spacing */
    
    color: aliceblue;

    text-align: left; /* Align text to the left */
    border-bottom: 3px solid grey;
    width: 90%; /* Make the h1 span the full width of the parent */
    box-sizing: border-box; /* Ensure padding is included in width calculation */
}

.detec-group
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.3rem;
    padding-top: 3%;
    padding-bottom: 6%;
    padding-left: 2%;
}

.detec-group p
{
    transition: 0.4s;
    cursor: pointer;
}

.detec-group p:hover
{
    scale: 1.08;
    transition: 0.4s;
}

/* CSS for input */ 
.input-box
{
    position: relative;
    width: 100%;
    margin-top: 20px;
}

.input-box input
{
    width: 100%;
    height: 100%;
    background: aliceblue;
    border: none;
    outline: none;
    border: 2px solid #151515;
    border-radius: 10px;
    font-size: 16px;
    padding: 1rem;
    color: #000;
    color-scheme: dark;
}

.input-box input:focus
{
    border-color: #151515;
    --tw-ring-color:#423f3f;
}

.input-box input::placeholder
{
    color: grey;
}

/* .input-box input:focus {
    border-color: #ff4d00 !important;
    box-shadow: #e5640c !important;
  } */


.input-box .icon1
{
    position: fixed;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 16px;
}

.fnn-popup-logo
{
    width: 6.5rem; /* Size of the logo item */
    margin: 0.5rem;
}

.fake-lightning-overlay {
    position: absolute;
    top: 50;
    right: 30%;
    width: 50rem;
    height: fit-content;
    pointer-events: none; /* Ensures it doesn't interfere with map interactions */
    opacity: 0.5;
    z-index: 9999; /* Ensure it appears above the map */
}

.fake-lightning-overlay-chart
{
    position: absolute;
    top: 81%;
    right: 93%;
    width: 6rem;
    height: 8rem;
    pointer-events: none; /* Ensures it doesn't interfere with map interactions */
    z-index: 9999; /* Ensure it appears above the map */
}

.marker-legend {
    position: fixed; /* Fixed positioning relative to viewport */
    bottom: 1.5rem; /* Distance from bottom */
    left: 1.5rem; /* Distance from left */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;

    background-color: #2b2b2b;
    opacity: 0.9;
    border: 0.15rem solid grey;
    border-radius: 0.75rem;
    overflow: hidden;

    width: 11rem;
    height: 13rem;
    pointer-events: none; /* Ensures it doesn't interfere with map interactions */
    z-index: 9999; /* Ensure it appears above other content */
}


.legend-markers
{
    width: 2rem; /* Size of the logo item */
    margin: 0.5rem;
}

.marker-and-description
{
    display: flex;
    align-items: center;
    justify-content: center;
}

.marker-and-description p
{
    color: aliceblue;
    font-size: 0.75rem;
    padding-top: 1rem;
}

@media (max-width: 1650px)
{
    .arrow-and-dash
    {
        margin-left: 75%;
        margin-top:10%;
        margin-bottom: 5%;
    }

    .my-dash
    {
        width: 19rem;
        height: 34rem;
        padding: 0rem 1rem;
    }

    .my-dash h1 {
        margin-left: 0; /* Remove the left margin */
        margin-top: 0.8rem; /* Keep the top margin for spacing */
    }


    .online-offline
    {
        margin-top: 0.8rem;
    }

    .online-offline h2
    {
        margin-top: 0.2rem; /* Keep the top margin for spacing */
    }
    /* CSS for input */ 
    .input-box
    {
        margin-top: 10px;
        height: 3rem;
    }

    .input-box input
    {
        width: 100%;
        height: 100%;
        font-size: 1rem;
    }

    .leaflet-geosearch-bar {
        top: 10%; /* Position from the top of the screen */
    }
}

@media (max-width: 1200px)
{
    .arrow-and-dash
    {
        margin-left: 65%;
    }

    .leaflet-geosearch-bar {
        width: 18rem !important;
        top: 12%; /* Position from the top of the screen */
    }
}

@media (max-width: 1000px)
{
    .leaflet-geosearch-bar {
        top: 14%; /* Position from the top of the screen */
        left: 40%;
    }
}

@media (max-width: 950px)
{
    .arrow-and-dash
    {
        margin-left: 60%;
    }

}

@media (max-width: 800px)
{

    .arrow-and-dash
    {
        margin-left: 63%;
    }

    .my-dash
    {
        width: 16rem;
        height: 34rem;
    }
    .leaflet-geosearch-bar {
        width: 18rem !important;
        top: 12%; /* Position from the top of the screen */
    }
}

@media (max-width: 699px)
{
    .arrow-and-dash
    {
        position: absolute;
        margin-top: 15%;
        margin-left: 0%;
        margin-right: 0%;
        margin-bottom: 0%;
        width: 98%;
        height: 80%;
    }
    
    .marker-legend 
    {
        display: none;
    }

    .my-dash
    {
        opacity: 0.9;
        
        width: 100%;
        height: 100%;
        padding: 0.2rem 1rem;
    }

    .leaflet-control-zoom {
        padding-left: 10% !important;
    }

    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out {
        width: 2rem !important; /* Set button width */
        height: 2rem !important; /* Set button height */
    }

    .leaflet-geosearch-bar {
        width: 18rem !important;
        top: 10%; /* Position from the top of the screen */
        left: 50%;
    }
}

@media (max-width: 400px)
{
    .arrow-and-dash
    {
        margin-top: 20%;
    }
}
