.notification {
  position: fixed;
  width: 65vw;
  height: 65vh;
  top: 20%;
  background-color: black;
  color: black;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 0 0.1rem rgba(245, 149, 79, 1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 3rem;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}
.pageapply {
  margin-top: 100px;
}

.form-group{
  display: grid;
  justify-items: center;
}

.flex-container-0,
.flex-container-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
}

.flex-container-1 {
  align-items: center !important;
  gap: 3rem;
}

.name-row {
  display: flex;
  justify-content: flex-start;
  width: auto;
  gap: 2rem;
}

.text-box-label {
  color: white;
  font-size: x-large !important;
}

.custom-input,
.custom-input-names,
.custom-dropdown-menu {
  border: 1px solid #ccc;
  font-size: 1rem;
  color: white;
  background-color: #585858;
  outline: none;
  -webkit-box-shadow: 0 0 0px 1000px #585858 inset;
  transition: background-color 5000s ease-in-out 0s;
}

.custom-input:focus,
.custom-dropdown-menu:focus,
.custom-input-names:focus {
  border-color: #f5954f;
  box-shadow: 0 0 0 0.05rem rgba(245, 149, 79, 1);
}
.custom-input {
  width: 450px;
}

.custom-dropdown-menu {
  width: 450px;
  height: 40px;
}
.btn-upload {
  padding: 10px 61px;
  border: 1px solid #ccc;
  font-size: 1rem;
  color: white;
  background-color: #585858;
  outline: none;
  width: max-content;
}
.submit-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.submit-btn {
  padding: 10px 100px;
  border: 1px solid #ccc;
  font-size: 1rem;
  color: #f5954f;
  background-color: #232222;
  outline: none;
}

@media (max-width: 470px) {
  .custom-input-names {
    width: 335px;
  }
  .text-box-label {
    font-size: large !important;
  }
  .custom-input {
    width: 335px;
  }
  .custom-dropdown-menu {
    width: 335px;
  }
  .name-row {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: auto;
    gap: 2rem;
  }
}

/* To fade in and out notifications */

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

.fadeOut {
  animation-name: fadeOut;
}

.custom-input:-webkit-autofill,
.custom-input-names:-webkit-autofill,
.custom-dropdown-menu:-webkit-autofill {
  -webkit-text-fill-color: white !important;
  -webkit-box-shadow: 0 0 0px 1000px #585858 inset;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: white;
}
