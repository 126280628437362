.FNNFooterLogo{
    width: 50%;
    height: auto;
    margin-left: 30%;
}



.iframe-container{
    width: 100%;
    height: 600px;

    
}

.iframe{
    width: 540px;
    height: 600px;

}
@media (max-width: 500px) {
    .iframe-container{
        height: 640px;
    
    }
    .iframe{
        height: 670px;
    
    }
}