.table-header{
  --bs-table-bg: #282c34;
}

.bnt-download {
  color: aliceblue;
  background-color: #e5640c;
  border: 0px solid transparent;
  padding: 3px 10px;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
}

.bnt-download:hover {
  background-color: #df722a;
}

.groupfilter{
  background-color: transparent !important;
  --bs-table-hover-bg: transparent !important;
}

.warning-column{
  color: #975020 !important;
  font-weight: bold;
}

.error-column{
  color: #912626 !important;
  font-weight: bold;
}

.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup-image {
  border-radius: 5px;
  max-width: 85vw;
  max-height: 90vh;
  display: block;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  
  /* Use flexbox properties to create a square button */
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  min-width: 40px; /* Set a minimum width for the button */
  min-height: 40px; /* Set a minimum height for the button */
}

.plot-button {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px;
  background-color: #868686;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  
  /* Use flexbox properties to create a square button */
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  min-width: 40px; /* Set a minimum width for the button */
  min-height: 40px; /* Set a minimum height for the button */
}

.table-responsive {
  overflow-x: auto; /* Allow horizontal scrolling on small screens */
}

.loading-circle {
  position: absolute;
  top: 50%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid #ffffff;
  border-top: 4px solid #3498db;
  animation: spin 2s linear infinite;
  z-index: 2;
}

.red{
  color: #912626;
  font-weight: bold;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 768px) {

  .expanded-table{
    width: "100%";
    display:"block";
  }

  .expanded-table td:first-child {
    border-bottom: transparent; /* Adds a white border only to the last cell */
  }

  .table th, .table td {
      font-size: 0.9em; /* Adjust font size for better readability */
  }

  .table {
      display: block; /* For smaller screens, treat table as block */
      overflow: auto; /* Allow scrolling */
      width: 100%;
  }

  .table th, .table td {
      display: block; /* Stack table header and cell */
      width: 100%; /* Full width for each cell */
      box-sizing: border-box; /* Include padding in width */
  }

  .table tr {
      border-bottom: 1px solid #ccc; /* Add border for separation */
  }
}