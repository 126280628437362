/* FNN Title and Image */

.particleshome {
  object-fit: contain;
  position: relative;
}

.top-particles-container {
  aspect-ratio: 25/9;
}

.home-img-top {
  object-fit: cover;
}

#benefitsParticles {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
}

#topParticles {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.Home {
  background-color: black;
}

.FNN {
  color: white;
  font-size: var(--font-large);
  justify-self: center;
  padding-top: 2rem;
}

.NetworkMountains {
  width: 100vw;
}
@media (max-width: 414px) {
  .NetworkMountains {
    padding-top: 5rem;
    width: 100vw;
  }
}

/* Fire Slogan */
.title {
  font-size: var(--font-title);
  color: #f5954f;
  padding: 1rem 1rem;
}

/* Semi-Rectangle Text Section */
.grid-container {
  display: grid;
  gap: 4rem;
  grid-template-columns: repeat(2, 1fr);
  text-align: left;
  margin: 2rem 0rem;
  align-items: center;
  height: 100%;
}

.grid-group {
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 3.3fr;
  align-items: center;
  justify-items: center;
}

.grid-group::before {
  content: '';
  position: absolute;
  width: 80%;
  height: 2px;
  background-color: #fff;
  z-index: 2;
  top: 0;
  right: 0;
}

.grid-group::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
  z-index: 2;
  bottom: 0;
}

.separator {
  width: 100%;
  height: 100px;
}

.semi-rectangle {
  width: 100%;
  font-size: var(--font-x-small);
  margin: 2rem;
  padding: 0rem 2rem;
}

.semi-rectangle::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #fff;
  z-index: 2;
  right: 0;
  top: 0;
}

.grid-group-flip {
  height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 3.3fr 1fr;
  align-items: center;
  justify-items: center;
}

.grid-group-flip::before {
  content: '';
  position: absolute;
  width: 80%;
  height: 2px;
  background-color: #fff;
  z-index: 2;
  top: 0;
  left: 0;
}

.grid-group-flip::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #fff;
  z-index: 2;
  bottom: 0;
}

.semi-rectangle-flip {
  width: 100%;
  font-size: var(--font-x-small);
  margin: 2rem;
  padding: 0rem 2rem;
}

.semi-rectangle-flip::after {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  background-color: #fff;
  z-index: 2;
  left: 0;
  top: 0;
}

/* Intelligence Slogan */
.slogan {
  font-size: var(--font-medium);
  letter-spacing: 0.03em;
  font-weight: 400;
  color: #fff;
}

.line {
  display: grid;
  align-items: center;
  margin: 0 auto;
  width: 85%;
  height: 1px;
  background-color: #fff;
  margin-bottom: 2rem;
}

/* Forest Lightning Image */
.LightForest {
  width: 100vw;
  height: 70vh;
}

/* Background and Benefits Section */
.bg-ben-container {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-evenly;
  padding: 2rem;
  gap: 3rem;
  margin: 1rem 1rem;
  position: relative;
}

.background-title {
  color: white;
  font-size: var(--font-small);
  margin: 1rem;
}

.background-text {
  text-align: left;
  font-size: var(--font-x-small);
  color: rgba(255, 255, 255, 0.5);
}

.benefits-container {
  text-align: left;
  color: rgba(255, 255, 255, 0.5);
  padding-right: 1rem;
}

.benefitsNum-container {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
}
@media (max-width: 1023px) {
  .bg-ben-container {
    padding: 1rem;
    gap: 2rem;
    margin: 0rem 1rem 1rem 1rem;
  }
  .benefitsNum-container {
    gap: 1rem;
  }
}
@media (max-width: 810px) {
  .grid-container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }
  .slogan {
    margin-top: 2rem;
  }
  .bg-ben-container {
    grid-template-columns: 1fr;
    padding: 0rem;
    gap: 1rem;
    margin: 1rem 1rem;
  }
  .benefitsNum-container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}

.number-circle {
  border: 2px solid #fff;
  border-radius: 50%;
  font-size: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.5rem;
  padding: 2rem;
  height: 3.5rem;
  color: #fff;
}
@media (max-width: 780px) {
  .number-circle {
    border: 1px solid #ffffff6c;
    font-size: 1rem;
    width: 2rem;
    padding: 0.5rem;
    height: 2rem;
    color: #fff;
  }
}

.number-circle:hover {
  background-color: #a3a3a3;
  color: #000;
}

/* Accuracy Slogan */
.instant {
  font-size: var(--font-medium);
  color: #f5954f;
  padding: 2rem 1rem;
  text-align: center;
  overflow: hidden;
}

.image-description-learnmore-container {
  position: relative;
  display: inline-block;
}

/* Detector Installation and Firebird Image */
.TwoHappyBois {
  z-index: -1;
  width: 100vw;
  height: 90vh;
}

/* Overlayed Text and Button */
.description-image-overlay {
  position: absolute;
  top: 25%;
  left: 8%;
  display: grid;
  align-items: center;
  justify-items: center;
  gap: 1rem;
}
@media (max-width: 810px) {
  .description-image-overlay {
    position: absolute;
    top: 25%;
    left: 18%;
    display: grid;
    align-items: center;
    justify-items: center;
    gap: 1rem;
  }
}

.description-overlay {
  position: relative;
  width: 249px;
  height: 300px;
  background: rgba(0, 0, 0, 0.64);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  padding: 15px;
}

.learnMore-button {
  position: relative;
  width: 218px;
  height: 56px;
  background: rgba(0, 0, 0, 0.76);
  border-radius: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f5954f;
  font-size: 25px;
  line-height: 24px;
  text-align: center;
  padding: 20px;
}

.learnMore-button:hover {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.9);
}

/* IDK */
.Firebird {
  z-index: -1;
  width: 100vw;
}


canvas {
  width: 100%;
  height: 100%;
}

.home {
  margin-top: 5.5rem;
}

/* Banner */

.banner {
  width: 100%;
  background-color: #e5640d;
  padding: 1px 0;
  position: relative;
  
  z-index: 10;
}

.banner-text {
  color: #000;
  font-size: 1.5rem;
  white-space: nowrap;
  display: block;
  position: relative;
  animation: marquee 40s linear infinite;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  animation-timing-function: cubic-bezier(1, 1, 1, 1);
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
  }
}
