/* Rotating Detector Section */
.wrapper {
  display: flex;
  justify-content: space-between;
  margin: 0 5rem;
}

.main {
  width: 65%;
  height: 100%;
  min-height: 1000px;
  display: flex;
  flex-direction: column;
}
.main {
  color: white;
  padding: 15px;
}

@media (max-width: 768px) {
  .wrapper {
    display: block;
    margin: 0 1rem;
  }
  
  .main {
    width: 100%;
  }
}

.about-detect {
  color: #ffffff;
  font-size: var(--font-small);
  padding: 1rem;
  margin-bottom: 15%;
  text-align: center;
}

.about-detect p {
  font-size: var(--font-small);
}

.overview {
  color: #ffffff;
  font-size: var(--font-large);
  margin: 2rem 5rem;
  text-align: center;
}

/* Rotating Detector */
.sidebar {
  scrollbar-width: none; /* Firefox */
  width: 30%;
  height: 75vh; /* Ensure this is enough space */
  min-height: 200px;
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  display: flex; /* Changed to flex for better alignment */
  align-items: center;
  justify-content: center; /* Centers content horizontally */
}

.detect {
  height: 100%;
  object-fit: contain;
  overflow: hidden;
}

/* Performance and Speed Section */
.Overlay-right {
  display: grid;
  gap: 1rem;
  height: 100%;
  width: 100%;
  padding: 5rem;
  background-image: url('../img/detector/lightningBg.jpg');
  background-size: cover;
}

.Overlay-right div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.Overlay-right p {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  width: 50%;
  font-size: var(--font-small);
}
.Overlay-right p {
  color: #ffffff;
  background: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  margin-bottom: 2rem;
}

@media (max-width: 1024px) {
  .Overlay-right {
    padding: 5rem;
  }
  .Overlay-right p {
    width: 100%;
  }
  .performance-title {
    width: 100%;
  }
}

@media (max-width: 780px) {
  .Overlay-right {
    padding: 2rem;
  }
}

.performance-title {
  color: white;
  font-size: var(--font-medium);
  width: 50%;
}

/* Detectors going into Background Section */
.detector-infinity-bg {
  position: relative;
  width: 100%;
  height: 100%;
}

.detector-infinity-bg p {
  position: absolute;
  top: 60%;
  left: 50%;
  width: 50%;
  padding: 2rem;
  z-index: 2;
  color: #ffffff;
  font-weight: 400;
  font-size: var(--font-small);
}
@media (max-width: 1024px) {
  .detector-infinity-bg p {
    position: relative;
    bottom: 0%;
    left: 0%;
    width: 100%;
  }
}

/* Gallery Section */
.Carouselpics {
  width: 100%;
}

.swiper-slide img {
  object-fit: contain;
}

/* IDK */
img.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 20%;
  padding: 4rem 0rem;
  width: 200px;
  z-index: 1;
}

.lightning-pic {
  width: 100%;
  padding-top: 15rem;
}

.image-description-learnmore-container {
  position: relative;
  display: inline-block;
}

@media (max-width: 800px) {
  .sidebar {
    width: 100%;
    margin-bottom: 2rem;
  }
}

@media (max-width: 510px) {
  
.performance-title {
  width: 100%;
}
}