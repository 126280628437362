.img-sides{
  height:100%;
}

.gatorX_main {
  margin-top: 100px;
}
.flexContainer0 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;
  width: 100vw;
}

.gridContainer0 {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: flex-start;
}

.gridContainer0 > * {
  text-align: center;
  padding: 2rem;
  font-size: var(--font-small);
}

.headerContainer {
  position: relative;
  width: 100vw;
}
.headerImage {
  width: 100%;
}

.gatorXTitle {
  font-size: var(--font-title);
}
.sloganLabel {
  font-size: var(--font-sm-md);
}
.money {
  font-size: var(--font-medium);
}
@media (max-width: 1335px) {
  .sloganLabel {
    font-size: var(--font-small);
  }
}
@media (max-width: 1035px) {
  .sloganLabel {
    font-size: var(--font-x-small);
  }
  .gatorXTitle {
    font-size: var(--font-medium);
  }
  .money {
    font-size: var(--font-small);
  }
}

.boxGrid {
  position: absolute;
  background-color: black;
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  width: 50%;
  transform: translate(0%, -85%);
  align-items: center;
  justify-items: center;
}
.boxFlex {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.lightningLogo {
  filter: brightness(0) invert(1);
  width: 60%;
}

.xprizeQuote {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
}

.xprizeQuote > img {
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
  position: absolute;
}
.QuoteContainer {
  display: grid;
  grid-template-rows: auto;
  position: inherit;
  align-items: center;
  justify-items: center;
  height: 100%;
  gap: 1rem;
  padding: 10rem 3rem;
}
.Quote,
.Quote2 {
  color: white;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}
.Quote {
  font-size: var(--font-medium);
}
.Quote2 {
  font-size: var(--font-small);
}
.Quote3 {
  color: white;
  text-transform: none;
  text-align: center;
  font-weight: 100;
  font-size: var(--font-x-small);
}

.flexContainer1 {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100vw;
  padding: 0 3rem;
}
.whatIsTheChallenge {
  font-size: var(--font-title);
  align-self: flex-start;
}
.font-body{
  font-size: var(--font-small);
  color: white;
}

.gridContainer1 {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: flex-start;
}

.gridContainer1 > * {
  box-sizing: border-box;
  /* Add any additional styling for grid items here */
  padding: 3vw;
  text-align: center;
  font-size: var(--font-small);
  line-height: 1;
}
.inverted {
  transform: scalex(-1)
}
.moreInformation {
  font-size: var(--font-sm-md);
  color: grey;
  line-height: 1;
}
.xprizeLink {
  font-size: var(--font-sm-md);
  color: orange;
  line-height: 1;
}
.missionstatement {
  color: white;
  text-align: center;
  font-size: var(--font-large);
  padding-bottom: 2rem;
}
@media (max-width: 1000px) {
  .gridContainer1 > * {
    font-size: var(--font-sm-md);
  }
  .missionstatement {
    font-size: var(--font-medium);
  }
}
.gridContainer2 {
  width: 93%;
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 33.33% 33.33% 33.33%;
  gap: 2rem;
}

.gridContainer2 img {
  /* width: 100%; */
  height: 100%;
}

.flexContainer2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5rem;
  margin: 5rem;
}
.gatorXPartnersTitle,
.gatorXInternTeam {
  width: auto;
  color: white;
  font-size: var(--font-medium);
  text-align: center;
  padding: 1vw 1vw;
  border: 1px solid;
}

@media (max-width: 794px) {
  .gatorXPartnersTitle,
  .gatorXInternTeam {
    font-size: var(--font-medium);
  }
}
.flexContainer2-Row,
.flexContainer2-Row1 {
  width: 100%;
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: center;
  gap: 5rem;
}

.flexContainer2-Row img,
.flexContainer2-Row1 img {
  border-radius: 20px;
}

.flexContainer2-Row1 {
  grid-template-columns: 1fr 2fr;
}
.flexContainer3 {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.interndescription {
  color: white;
  font-size: var(--font-small);
  text-align: center;
  padding: 2rem 2rem 0vw 2rem;
}
.teamListTitle {
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
  font-size: var(--font-small);
}

.teamList {
  color: white;
  font-size: var(--font-small);
  text-align: center;
  padding: 2rem 2rem 0vw 2rem;
}

.gridContainer3 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  align-items: center;
}

.gridContainer4 {
  padding: 3vw 1vw;
  padding-left: 3vw;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.applyButton {
  width: auto;
  color: orange;
  font-size: var(--font-small);
  text-align: center;
  padding: 0.5rem 4rem;
  border: 1px solid;
  margin-top: 1rem;
}

.slick-slider {
  height: 50vh;
  width: 80vw;
  max-height: 50vw;
  max-width: 80vw;
  margin: 1rem;
}
@media (max-width: 768px) {
  .gatorX_main {
    margin-top: 60px;
  }
  .boxGrid {
    width: 100%;
  }
  .lightningLogo {
    width: 30%;
  }
  .gridContainer0 {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
  }
  .gridContainer0 > * {
    text-align: center;
    padding: 0.5rem;
    font-size: var(--font-small);
  }
  .QuoteContainer {
    padding: 2rem 1rem;
  }
  .Quote2,
  .Quote3 {
    font-size: var(--font-x-small);
  }
  .gridContainer1 {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
  }
  .gridContainer1 > * {
    font-size: var(--font-small);
  }

  .moreInformation {
    font-size: var(--font-small);
  }
  .xprizeLink {
    font-size: var(--font-small);
  }
  .missionstatement {
    font-size: var(--font-small);
  }
  .interndescription {
    font-size: var(--font-x-small);
    padding: 1rem 1rem 0vw 1rem;
  }
  .teamListTitle {
    padding-top: 1rem;
    font-size: var(--font-x-small);
  }

  .teamList {
    font-size: var(--font-x-small);
    padding: 0rem 1rem 0vw 1rem;
  }

  .applyButton {
    font-size: var(--font-x-small);
    padding: 0.5rem 4rem;
    margin-top: 0.5rem;
  }
}

@media (max-width: 500px) {
  .flexContainer1{
    padding: 0 1rem;
  }
  .slick-slider{
    width: auto;
    max-width:none;
    max-height: none;
  }
  .missionstatement{
    padding-bottom: 0;
  }
  .flexContainer2{
    margin: 2rem;
    gap: 2rem;
  }
  .gatorXInternTeam{
    width: 80%;
  }
  .xprizeQuote > img {
    width: auto;
  }
  .Quote{
    font-size: var(--font-small);
  }
}
