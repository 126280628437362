/* Slogan */
.ver-text {
  color: white;
  font-size: var(--font-medium);
  text-align: center;
  margin: 5rem 1rem;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  
}


.firebirdbanner{
  padding-bottom: 65vh;
}

/* Firebird Lines Section */
.grid-firebird {
  display: grid;
  height: 100%;
  width: 100%;
  gap: 2rem;
  padding: 5rem 20%;
  align-items: center;
  align-content: space-evenly;
  justify-items: center;
  background-image: url('../img/firebird/fbirdBG.png');
  background-size: cover; /* Resize the background image to cover the entire container */
  margin-bottom: 2rem;
}

.grid-firebird .left-text {
  position: relative;
  font-size: var(--font-small);
  padding: 1rem;
}

.grid-firebird .right-text {
  position: relative;
  font-size: var(--font-small);
  padding: 1rem;
}

.grid-firebird .left-text::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: -20%;
  width: 120%;
  height: 100%;
  border-bottom: 2px solid white;
  border-left: 2px solid white;
  z-index: 1;
}

.grid-firebird .right-text::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: -20%;
  width: 120%;
  height: 100%;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
}

.left {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 2fr;
}

.leftLines {
  display: grid;
  justify-content: end;
  justify-items: end;
}

.line-1 {
  width: 7rem;
  border-bottom: 2px solid white;
}

.line-2 {
  width: 12rem;
  border-bottom: 2px solid white;
}

.line-3 {
  width: 17rem;
  border-bottom: 2px solid white;
}

@media (max-width: 940px) {
  .grid-firebird {
    padding: 5rem 15%;
  }

  .left {
    grid-template-columns: 1fr 4fr;
  }

  .right {
    grid-template-columns: 4fr 1fr;
  }

  .line-1 {
    width: 3em;
    border-bottom: 2px solid white;
  }

  .line-2 {
    width: 7em;
    border-bottom: 2px solid white;
  }

  .line-3 {
    width: 12em;
    border-bottom: 2px solid white;
  }
}

.arrowfb {
  height: 2em;
  width: auto;
}

.right {
  display: grid;
  gap: 1rem;
  grid-template-columns: 2fr 1fr;
}

.right-text {
  text-align: right;
  margin: 0;
}

.rightLines {
  display: grid;
}

@media (max-width: 600px) {
  .grid-firebird {
    padding: 5rem 1rem;
  }

  .grid-firebird .left-text::after {
    left: 0%;
    width: 100%;
    height: 100%;
  }

  .grid-firebird .right-text::after {
    left: 0%;
    width: 100%;
    height: 100%;
  }

  .left {
    grid-template-columns: 1fr 4fr;
    gap: 0rem;
  }

  .right {
    grid-template-columns: 4fr 1fr;
    gap: 0rem;
  }

  .line-1 {
    width: 0%;
  }

  .line-2 {
    width: 0%;
  }

  .line-3 {
    width: 0%;
  }

 
}

/* YouTube Embed Video */
.youtube-container {
  width: 100%;
  align-self: center;
  justify-self: center;
  /* Keep it the right aspect-ratio */
  aspect-ratio: 16.85/5;
  /* No clicking/hover effects */
  pointer-events: none;
  margin-bottom: 2rem;
}

.youtube-container iframe {
  width: 100%;
  height: 100%;
}

/* Images with Overlayed Text Section */
.fbird-image-overlay img {
  object-fit: cover;
  height: 100%;
}
.fbird-image-overlay  {
  width: 100%;
}
.fbird-text{
  color: white;
  text-align: center;
  padding: 1rem;
}

/* IDK */
.grid-bases {
  display: grid;
  grid-gap: 1rem;
  justify-items: center;
  height: 100%;
  width: 100%;
}

.fbirdAbout {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
