.AppBackend {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  max-width: 100vw;
  font-size: 1rem;
  overflow: hidden;
}

.backend .body {
  margin: clamp(5rem, 3.636rem + 5.818vi, 8rem) 0rem;
}
.backend table {
  font-size: 1.15rem;
}
.backend .App-logo {
  height: 3em;
  width: 7em;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.backend .display-flex {
  display: flex;
  gap: 2rem;
  justify-content: center;
}

.backend .header {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  width: 100vw;
  display: flex;
  align-items: center;
  background-color: #282c34;
}

.backend .App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.backend .App-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 1rem 0rem;
}

@keyframes App-logo-spin {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(5px);
  }
  50% {
    transform: translateX(-5px);
  }
  75% {
    transform: translateX(5px);
  }
  100% {
    transform: translateX(0);
  }
}

.backend .bnt-header {
  color: #afafaf;
  background-color: transparent;
  border: none;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  font-size: 1.5rem;
  position: relative;
  margin: 1.25rem;
}

.bnt-header::after {
  content: '';
  position: absolute;
  bottom: 0;
  width: 0;
  left: 0;
  height: 2px;
  background-color: #e5640c;
  transition: width 0.5s ease-in-out;
}

.bnt-header:hover::after {
  width: 100%;
  animation: expandContract 1s infinite;
}

@keyframes expandContract {
  0% {
    width: 0;
    left: 0%;
  }
  50% {
    width: 100%;
    left: 0%;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

.backend .active {
  color: aliceblue;
}

@media (max-width: 768px) {

  .backend .header {
    padding: 5px !important; /* Adjust padding */
    margin: 0;
  }

  .backend .display-flex {
    width: auto; /* Full width for buttons */
    display: flex;
    justify-content: space-around; /* Distribute buttons evenly */
  }

  .backend .bnt-header {
    flex: 1; /* Allow buttons to grow */
    padding: 2px; /* Add margin between buttons */
    font-size: 1rem;
    margin: 5px; /* Add margin between buttons */
  }

  .backend .App-logo {
    max-height: 2em;
    width: auto;
    margin-bottom: 10px; /* Space below logo */
  }
}
