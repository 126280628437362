.online-column {
  color: rgb(228, 243, 228) !important;
}

.offline-column td{
  color: rgb(97, 96, 96) !important;
}
.pointer {
  cursor: pointer;
}

.groupkey {
  --bs-table-hover-bg: #212529 !important;
}

@media (max-width: 768px) {
  tr td:last-child {
    border-bottom: 2px solid white; /* Adds a white border only to the last cell */
  }

  .online-column {
    border-bottom: transparent !important;
  }
  
}