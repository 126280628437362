/* Mission Background Image */
.grid-mission {
  position: relative; 
  display: grid;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  padding: 5rem;
  gap: 2rem;
}

/* Mission Statement */
.our-mission {
  align-self: center;
  justify-self: center;
  font-size: var(--font-x-large);
  margin-top: 4rem;
  align-self: flex-start;
}

.textColor {
  color: #f5954f;
}

/* Mission Youtube Video */
.youtube-mission {
  min-width: 80%;
  aspect-ratio: 16/9;
  align-self: center;
  justify-self: center;
}

.youtube-mission iframe {
  width: 100%;
  height: 100%;
}

/* Mission Texts */
.grid-block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 7rem;
  justify-items: center;
  align-items: center;
}

.block-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  padding: 1rem;
  height: 100%;
  width: 100%;
  font-size: var(--font-small);
  text-align: center;
}

/* Mission Video */
.video-diagram {
  max-width: 80%;
  aspect-ratio: 16/9;
  align-self: center;
  justify-self: center;
  width: 150vh
}

@media (max-width: 880px) {
  .grid-mission {
    padding: 5rem 1rem;
  }
  .grid-block {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
  .youtube-mission {
    max-height: 80vh;
    aspect-ratio: 16/9;
  }
  .video-diagram {
    max-height: 80vh;
    aspect-ratio: 16/9;
  }
}

/* IDK */
.grid-auto-rows {
  display: grid;
  grid-gap: 2rem;
  justify-items: center;
  align-items: center;
}
