@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Aldrich&display=swap');
@import "css/header.css";
@import "css/home.css";
@import "css/hrl-firebird.css";
@import "css/hrl-detector.css";
@import "css/mission.css";


html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
:root{
  --font-x-small: clamp(0.75rem, 0.455rem + 1.2422vw, 1.25rem);
  --font-small: clamp(1.125rem, 0.8668rem + 1.087vw, 1.5625rem);
  --font-sm-md: clamp(1.25rem, 0.8075rem + 1.8634vw, 2rem);

  --font-medium: clamp(1.5rem, 0.9099rem + 2.4845vw, 2.5rem);
  --font-large: clamp(2.1875rem, 1.0811rem + 4.6584vw, 4.0625rem);
  --font-title: clamp(2rem, 1.1149rem + 3.7267vw, 3.5rem);
  --font-x-large: clamp(2.5rem, 1.3199rem + 4.9689vw, 4.5rem);
  --font-family-default: 'Aldrich', sans-serif;
}


body.custom-body {
 /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-family: var(--font-family-default) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}
.flex {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.overlay-corners {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.7) 60%, rgba(0, 0, 0, 0.9) 70%, rgba(0, 0, 0, 1) 90%, rgb(0, 0, 1) 100%);
  z-index: 1;}

img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

#error-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.swiper-button-prev,
.swiper-button-next {
  color: #fff !important;
}


a.custom-a {
  color: transparent !important; /* Makes the link invisible */
}