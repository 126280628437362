main {
  overflow: hidden;
}
.page {
  margin-top: 100px;
  margin-left: clamp(1rem, 0.296rem + 3.005vw, 3rem);
  margin-right: clamp(1rem, 0.296rem + 3.005vw, 3rem);
}

.lazy-load-image-background{
  display: block !important;
  height: 100%;
  object-fit: cover !important;
}

.lazy-load-image-loaded {
  display: block !important;
}
.top-grid {
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 45% 55%;
  grid-gap: 1rem;
  margin-bottom: 2rem;
}

@media (max-width: 800px) {
  .top-grid {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }
  .welcomeFireDescription {
    font-size: var(--font-small) !important;
  }
  .welcomeFire-Img {
    padding: 2rem clamp(1rem, -3.412rem + 18.824vw, 6rem) !important;
  }
}

.flex-container-wf {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
.welcomeFire-Img {
  padding: 2rem clamp(1rem, -2.75rem + 7.5vw, 4rem);
}
.welcomeFire {
  padding: 1.25rem 4rem;
  width: auto;
  color: white;
  text-align: center;
  flex: 0 0 auto;
  border: 2.5px white solid;
  font-size: var(--font-small);
}
.welcomeFireDescription {
  color: white;
  padding: 0 clamp(1rem, -0.761rem + 7.512vw, 6rem);
  font-size: clamp(0.75rem, -0.031rem + 1.563vw, 1.375rem);
  text-align: center;
}
.author-grid {
  display: grid;
  color: white;
  font-size: var(--font-small);
}
.bottom-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  color: white;
  margin: 0 clamp(0rem, -4.577rem + 19.531vw, 13rem);
  font-size: var(--font-small);
  text-align: center;
  margin-bottom: 2rem;
}

.label {
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  padding: 10px;
}
.value {
  border-bottom: 2px solid white;
  padding: 10px;
}
